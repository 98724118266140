// Announcement.js
import React, { useState } from 'react';
import { Input, Button, Space, notification, Checkbox, Modal } from 'antd';
import axios from 'axios';
import AdminPasswordModal from './AdminPasswordModal'; // 引入管理員密碼模組

const Announcement = ({ storeMappings, onClose }) => {
    const [message, setMessage] = useState('');
    const [selectedStores, setSelectedStores] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false); // 控制管理員狀態
    const storeOptions = Object.keys(storeMappings); // 從 storeMappings 取得門市名稱數組

    const handleStoreChange = (checkedValues) => {
        setSelectedStores(checkedValues); // 更新選取的門市
    };

    const handleAnnouncementSubmit = async () => {
        if (selectedStores.length === 0) {
            notification.warning({
                message: '未選門市',
                description: '請至少選擇一個門市來發布通知。 ',
            });
            return;
        }

        const promises = selectedStores.map(storeName =>
            axios.post('https://inventory.edc-pws.com/api/announcement', { message, storeName: storeMappings[storeName] }) // 使用對應的門市編碼
        );

        try {
            await Promise.all(promises); // 等待所有公告請求完成
            notification.success({
                message: '通知發布成功',
                description: '所有選取的門市都將收到此通知。 ',
            });
            setMessage(''); // 清空輸入框
            setSelectedStores([]); // 清空已選門市
            onClose(); // 發布成功後關閉模態框
        } catch (error) {
            notification.error({
                message: '發布通知失敗',
                description: error.response?.data?.message || '請稍後再試。 ',
            });
        }
    };

    const handlePasswordSuccess = () => {
        setIsAdmin(true); // 驗證成功，允許發布通知
    };

    return (
        <Modal
            title={isAdmin ? "發佈通知" : "管理員驗證"}
            visible={true} // 確保模態框始終可見
            onCancel={onClose} // 使用傳入的關閉方法
            footer={null} // 不使用預設的 footer
        >
            {isAdmin ? (
                // 如果是管理員狀態，顯示發布通知內容
                <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: 'flex' }}
                >
                    <Input
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="輸入通知內容"
                    />
                    <Checkbox.Group onChange={handleStoreChange} value={selectedStores}>
                        {storeOptions.map(store => (
                            <Checkbox key={storeMappings[store]} value={store}>
                                {store} {/* 顯示門市名稱 */}
                            </Checkbox>
                        ))}
                    </Checkbox.Group>
                    <Button onClick={handleAnnouncementSubmit} type="primary">發布通知</Button>
                </Space>
            ) : (
                // 如果未通過驗證，顯示管理員密碼輸入框
                <AdminPasswordModal onSuccess={handlePasswordSuccess} />
            )}
        </Modal>
    );
};

export default Announcement;