
// AdminPasswordModal.js
import React, { useState } from 'react';
import { Input, Button, notification } from 'antd';
import Announcement from './Announcement'; // 引入公告组件

const AdminPasswordModal = ({ onSuccess }) => {
    const [password, setPassword] = useState('');

    const handlePasswordSubmit = () => {
        // 假设您的管理员密码存储在环境变量中
        const adminPassword = '12234'; // 将此替换为 process.env.REACT_APP_ADMIN_PASSWORD 以使用环境变量
        if (password === adminPassword) {
            onSuccess(); // 密码验证成功，调用父组件的 onSuccess
            setPassword(''); // 清空密码输入框
        } else {
            notification.error({
                message: '密碼不正確',
                description: '請檢查您的密碼並重試。',
            });
            setPassword(''); // 清空密码输入框
        }
    };

    return (
        <div>
            <Input.Password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="輸入管理員密碼"
                style={{
                    width: 200,
                }}
                focus={{ cursor: 'start' }}
                        />
            <div style={{ marginTop: '10px' }} />
            <Button onClick={handlePasswordSubmit} type="primary">提交</Button>
        </div>
    );
};

export default AdminPasswordModal;
