import React, { useState, useEffect, useRef } from 'react';
import { InputNumber, Spin, Modal, Radio } from 'antd';
import { LoadingOutlined, ExclamationOutlined, StopOutlined, CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import DisableProductModal from './DisableProductModal'; // 引入新組件

const ProductInput = React.forwardRef(({ product, index, handleQuantityChange, handleDepotChange, inputRefs }, ref) => {
    const [numericValue, setNumericValue] = useState(product.數量);
    const [numLoading, setNumLoading] = useState(false);
    const [numError, setNumError] = useState(false);
    const [numUploadStatus, setNumUploadStatus] = useState(null);
    const timerRef = useRef(null);
    const [isBlurTriggeredByUpload, setIsBlurTriggeredByUpload] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showDisableOptions, setShowDisableOptions] = useState(false); // 控制權是否顯示停用選項
    const [disableTimeout, setDisableTimeout] = useState(null); // 儲存逾時計時器 ID

    useEffect(() => {
        setNumericValue(product.數量);
    }, [product]);

    const handleInputCheck = (value) => {

        if (Number(value) < 0) {
            setNumError(true);
            setNumLoading(false);
            setNumUploadStatus('stopoutlined');
            setNumericValue(value);

            return;
        } 

            handleInputChange(value);
            setNumError(false);
    
    };

    const handleInputChange = (value) => {
        setNumericValue(value);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        // 若值為 0，先不顯示停用選項，而是先上傳
        if (value === 0) {
            timerRef.current = setTimeout(async () => {
                await uploadValue(value, true); // 傳入一個標誌，表示是 0 值的上傳
            }, 10000);
        } else {
            timerRef.current = setTimeout(async () => {
                await uploadValue(value, false); // 傳入一個標誌，表示不是 0 值的上傳
            }, 10000);
        }
    };

    const uploadValue = async (value, isZeroValue) => {
        setIsBlurTriggeredByUpload(false);
        setNumLoading(false);
        setNumUploadStatus('loading');
        if (product.數量 === value) {
            //setNumUploadStatus('exclamationoutlined');
            setTimeout(() => {
                setNumUploadStatus(null);
            }, 3000);
            return; // 如果數值沒有變化，直接返回
        }

        try {
            await handleQuantityChange(product.商品編號, value);
            setNumUploadStatus('success');
            setNumericValue(value);



            // 如果是 0 值的上傳，且上傳成功，則顯示停用選項
            if (isZeroValue) {
                setShowDisableOptions(true);
                // 設定 5 秒逾時，如果逾時則恢復顯示 InputNumber
                setDisableTimeout(setTimeout(() => {
                    setShowDisableOptions(false);
                }, 8000));
            }

        } catch (error) {
            setNumUploadStatus('error');
        } finally {
            setNumLoading(false);
            setTimeout(() => {
                setNumUploadStatus(null);
            }, 2000);
        }
    };

    const handleBlur = async () => {
        if (!numError) {
            if (isBlurTriggeredByUpload) {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }
                if (numericValue === 0) {
                    await uploadValue(numericValue, true); // 傳入一個標誌，表示是 0 值的上傳
                } else {
                    await uploadValue(numericValue, false); // 傳入一個標誌，表示不是 0 值的上傳
                }
            }
            setIsBlurTriggeredByUpload(true);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (inputRefs.current[index + 1]) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleCalculate = (result) => {
        setNumericValue(result);
        uploadValue(result, false); // 傳入一個標誌，表示不是 0 值的上傳
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (numError) {
                event.preventDefault();
                event.returnValue = '';
                setIsModalVisible(true);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [numError]);

    // 處理停用選項的選擇
    const handleDisableConfirmation = (shouldDisable) => {
        if (shouldDisable) {
            // 設庫別為 '未使用'
            handleDepotChange(product.商品編號, true);
        }
        // 清除逾時定時器
        if (disableTimeout) {
            clearTimeout(disableTimeout);
        }
        setShowDisableOptions(false); // 隱藏停用選項
    };

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {showDisableOptions ? (
                    <DisableProductModal
                        onConfirm={handleDisableConfirmation}
                    />
                ) : (
                    <InputNumber
                        ref={(el) => (inputRefs.current[index] = el)}
                        controls={false}
                        precision={2}
                        value={numericValue}

                        step="0.1"
                        suffix={numLoading ? (
                            <EditOutlined />
                        ) : numUploadStatus === 'loading' ? (
                            <Spin indicator={<LoadingOutlined spin />} />
                        ) : numUploadStatus === 'exclamationoutlined' ? (
                            <ExclamationOutlined style={{ color: 'blue' }} />
                                ) : numUploadStatus === 'stopoutlined' ? (
                                        <StopOutlined style={{ color: 'red' }} />
                        ) : numUploadStatus === 'success' ? (
                            <CheckOutlined style={{ color: 'green' }} />
                        ) : numUploadStatus === 'error' ? (
                            <CloseOutlined style={{ color: 'red' }} />
                        ) : product.單位}
                        onChange={handleInputCheck}
                            style={{ cursor: 'all', fontFamily: 'Roboto Mono', manWidth: '70px', textAlign: 'right', borderColor: numError ? 'red' : undefined }}
                            onFocus={() => {
                                setNumLoading(true);
                                setTimeout(() => {
                                    if (inputRefs.current[index]) {
                                        inputRefs.current[index].select(); // 選擇輸入框的所有文字
                                    }
                                }, 0);
                                if (product.數量 === numericValue) {
                                    setTimeout(() => {
                                        setNumLoading(false);
                                        setTimeout(() => {
                                            setNumUploadStatus(null);
                                        }, 2000);
                                        {
                                            if (!showDisableOptions) {
                                                if (inputRefs.current[index] && inputRefs.current[index + 1]) {
                                                    inputRefs.current[index].blur();
                                                }
                                            }
                                        }
                                    }, 5000);
                                }
                            }
                            }
                            onBlur={() => {
                                /*if (product.數量 === numericValue) {
                                    setNumLoading(false);
                                    setTimeout(() => {
                                        setNumUploadStatus(null);
                                    }, 2000);
                                } else*/ {
                                    handleBlur(); // 進行失去焦點的邏輯處理
                                    
                                }
                            }}
                        onKeyDown={handleKeyDown}
                    />
                )}
            </div>
            {isModalVisible && (
                <Modal
                    title="錯誤"
                    visible={isModalVisible}
                    onOk={() => setIsModalVisible(false)}
                    onCancel={() => setIsModalVisible(false)}
                >
                    <p>請在離開頁面前修正錯誤。 </p>
                </Modal>
            )}
        </>
    );
});

export default ProductInput;