// DisableProductModal.js
import React from 'react';
import { Modal, Radio } from 'antd';

const DisableProductModal = ({ visible, onClose, onConfirm }) => {
    const handleDisableChange = (e) => {
        const value = e.target.value;
        if (value === 'yes') {
            onConfirm(true); // 告訴父組件停用
        } else {
            onConfirm(false); // 不停用
        }
    };

    return (
        <Radio.Group onChange={handleDisableChange}>
            <Radio value="yes">停用?</Radio>
            <Radio value="no">返回</Radio>
        </Radio.Group>
    );
};

export default DisableProductModal;
