import React, { useState, useEffect } from 'react';
import { Modal, Input, Transfer, Table, Select, Button, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

const InventoryItemsManager = ({
    isOpen, fetchProducts, onClose, products, storeName, allVendors, allLayers }) => {
    const [targetKeys, setTargetKeys] = useState([]); // 用於管理右側選擇的品項
    const [searchTerm, setSearchTerm] = useState('');
    const [newProducts, setNewProducts] = useState([]); // 將被編輯的商品
    const [showEditModal, setShowEditModal] = useState(false); // 控制編輯模態框的顯示

    useEffect(() => {
        // 加載所有停用商品
        const inactiveItems = products.filter(item => item.停用);
        setTargetKeys([]); // 初始選擇為空
    }, [products]);

    const handleChange = (nextTargetKeys) => {
        setTargetKeys(nextTargetKeys);
    };

    const filterOption = (inputValue, option) =>
        option.title.toLowerCase().includes(inputValue.toLowerCase()); // 搜尋過濾

    const handleConfirm = () => {
        const selectedItems = products.filter(item => targetKeys.includes(item.商品編號));
        const updatedSelectedItems = selectedItems.map(item => ({
            ...item,
            停用: false // 將停用值設置為 false
        }));

        setNewProducts(updatedSelectedItems); // 設置新產品資料
        setShowEditModal(true); // 顯示編輯模態框
    };

    const handleVendorChange = (item, value) => {
        // 直接用新值更新狀態
        setNewProducts(prevProducts =>
            prevProducts.map(prod =>
                prod.商品編號 === item.商品編號 ? { ...prod, 廠商: value } : prod
            )
        );
    };

    const handleLayerChange = (item, value) => {
        // 直接用新值更新狀態
        setNewProducts(prevProducts =>
            prevProducts.map(prod =>
                prod.商品編號 === item.商品編號 ? { ...prod, 庫別: value } : prod
            )
        );
    };

    const handleSubmit = async () => {
        try {
            await axios.put(`/api/products/${storeName}/batch-update`, newProducts.map(item => ({
                商品編號: item.商品編號,
                廠商: item.廠商,
                庫別: item.庫別,
                停用: false
            })));
            message.success('數據提交成功！');
            setShowEditModal(false);
            fetchProducts();
            onClose(); // 關閉主模態框
        } catch (error) {
            console.error('提交數據失敗:', error);
            message.error('提交數據失敗！');
        }
    };

    const transferData = products
        .filter(item => item.停用) // 只顯示停用的商品
        .map(item => ({
            key: item.商品編號,
            title: item.商品名稱,
            停用: item.停用,
        }));

    return (
        <>
            <Modal
                title="盤點品項管理"
                width={700}
                open={isOpen}
                onCancel={onClose}
                footer={[
                    <Button key="submit" onClick={handleConfirm} type="primary">
                        提交資料
                    </Button>,
                    <Button key="cancel" onClick={onClose}>
                        取消
                    </Button>,
                ]}
            >
                <Input
                    placeholder="搜尋..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ marginBottom: '10px' }}
                />
                <Transfer
                    dataSource={transferData.filter(item => filterOption(searchTerm, item))}
                    targetKeys={targetKeys}
                    onChange={handleChange}
                    titles={['停用品項', '已選擇品項']}
                    render={item => item.title}
                    showSearch
                    filterOption={filterOption}
                    listStyle={{
                        width: 300,
                        height: 400,
                    }}
                />
            </Modal>

            {/* 編輯新設非停用商品的模態框 */}
            <Modal
                title="編輯新設非停用商品"
                open={showEditModal}
                onCancel={() => setShowEditModal(false)}
                footer={[
                    <Button key="submit" onClick={handleSubmit} type="primary">
                        提交
                    </Button>,
                    <Button key="cancel" onClick={() => setShowEditModal(false)}>
                        取消
                    </Button>,
                ]}
            >
                <Table
                    dataSource={newProducts.map(item => ({
                        key: item.商品編號,
                        title: item.商品名稱, // 確保商品名稱出現在表格中

                    }))}
                    columns={[
                        { title: '商品名稱', dataIndex: 'title' },
                        {
                            title: '廠商',
                            dataIndex: '廠商',
                            render: (_, item) => (
                                <Select
                                    value={item.廠商}
                                    onChange={(value) => handleVendorChange(item, value)} // 更新廠商
                                    style={{ width: '100%' }}
                                >
                                    {allVendors.map(vendor => (
                                        <Option key={vendor} value={vendor}>{vendor}</Option>
                                    ))}
                                </Select>
                            ),
                        },
                        {
                            title: '庫別',
                            dataIndex: '庫別',
                            render: (_, item) => (
                                <Select
                                    value={item.庫別}
                                    onChange={(value) => handleLayerChange(item, value)} // 更新庫別
                                    style={{ width: '100%' }}
                                >
                                    {allLayers.map(layer => (
                                        <Option key={layer} value={layer}>{layer}</Option>
                                    ))}
                                </Select>
                            ),
                        },
                    ]}
                    pagination={false}
                    rowKey="key" // 使用唯一鍵
                />
            </Modal>
        </>
    );
};

export default InventoryItemsManager;
