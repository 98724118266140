//Calculator.js

import React, { useState, useRef } from 'react';
import { create, all } from 'mathjs';
import CalculatorButton from './CalculatorButton';
import './style/CalculatorButton.css';


const math = create(all, {}); // 创建 mathjs 实例

const Calculator = ({ onCalculate, onClose, productName, currentIndex, setFocusToNextInput }) => {
    const [input, setInput] = useState('');
    const inputRef = useRef(null);

    const handleNumberClick = (num) => {
        setInput((prev) => prev + num);
    };

    const handleOperatorClick = (operator) => {
        setInput((prev) => prev + operator);
    };

    const calculateResult = () => {
        try {
            const result = math.evaluate(input);
            if(result<0){
                notification.warning({
                message: '錯誤',
                description: '計算結果不可為負數！',
            });

            }
            onCalculate(result);
            setInput('');
            onClose();
            //setFocusToNextInput(currentIndex); // 移动焦点到下一个输入框
        } catch (error) {
            alert('計算錯誤：' + error.message);
        }
    };

    return (
        <div className="calc-container" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '20px', borderRadius: '10px', backgroundColor: '#f1f1f1' }}>
            <span>{productName}</span> {/* 显示商品名称 */}
            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#ffffff', marginBottom: '5px' }}>
                <input className="calc-input" type="text" value={input} readOnly />
                <span onClick={() => setInput((prev) => prev.slice(0, -1))} className="material-symbols-outlined">backspace</span>
                <span className="material-symbols-outlined" onClick={() => setInput('')}>delete</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <CalculatorButton type="execute" label="‰" onClick={() => handleOperatorClick('/1000')} />
                {['(', ')', '+'].map(value => (
                    <CalculatorButton type="execute" key={value} label={value} onClick={() => handleOperatorClick(value)} />
                ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {['7', '8', '9'].map(value => (
                    <CalculatorButton key={value} label={value} onClick={() => handleNumberClick(value)} />
                ))}
                <CalculatorButton type="execute" label="-" onClick={() => handleOperatorClick('-')} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {['4', '5', '6'].map(value => (
                    <CalculatorButton key={value} label={value} onClick={() => handleNumberClick(value)} />
                ))}
                <CalculatorButton type="execute" label="×" onClick={() => handleOperatorClick('*')} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {['1', '2', '3'].map(value => (
                    <CalculatorButton key={value} label={value} onClick={() => handleNumberClick(value)} />
                ))}
                               <CalculatorButton type="execute" label="÷" onClick={() => handleOperatorClick('/')} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {['00', '0'].map(value => (
                    <CalculatorButton key={value} label={value} onClick={() => handleNumberClick(value)} />
                ))}
                {['.'].map(value => (
                    <CalculatorButton key={value} label={value} onClick={() => handleOperatorClick(value)} />
                ))}
                <CalculatorButton type="execute" label="=" onClick={calculateResult} />
            </div>
        </div>
    );
};

export default Calculator;