import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import axios from 'axios';
import ConfirmModal from './ConfirmModal'; // 引入確認模組
import Swal from 'sweetalert2'
import BouncyComponent from './BouncyComponent';
import {Modal, Table, Checkbox, Spin, Select, Button } from 'antd';
import '../components/style/Modal.css'; // 導入 Modal 的 CSS 樣式
import '../components/style/InventoryItemsManager.css'; // 導入 Modal 的 CSS 樣式
const apiUrl = process.env.REACT_APP_API_UR;
const { Option } = Select; // 确保你是从 Select 导入 Option

const InventoryUploader = forwardRef((props, ref) => {
    const [firstNewProducts, setFirstNewProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState({});
    const [loading, setLoading] = useState(false);
    const [completedProducts, setCompletedProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [year, setYear] = useState(2024);
    const [month, setMonth] = useState(12);
    const [store, setStore] = useState(null);
    const [serverConnected, setServerConnected] = useState(null);
    const [eposConnected, setEposConnected] = useState(null);
    const [checkingConnections, setCheckingConnections] = useState(false);
    const [checkProgress, setCheckProgress] = useState('');
    const [isUploaderOpen, setIsUploaderOpen] = useState(false);
    const [confirmModalContent, setConfirmModalContent] = useState({});
    const [modalContent, setModalContent] = useState({});
    const [modalAction, setModalAction] = useState(null);
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

    // 使 handleStartInventory 可從父組件調用
    useImperativeHandle(ref, () => ({
        startInventory: handleStartInventory
    }));



    const handleStartInventory = async () => {
        setServerConnected(null);
        setEposConnected(null);
        setStore(null);
        setIsUploaderOpen(true);
        setCheckingConnections(true);
        setCheckProgress('正在檢查門市資訊...');
        await delay(1500); // 等待1秒
        if (!props.storeName) {
            console.error('Store name is required');
            setCheckProgress('錯誤!未選門市...');
            await delay(1000); // 等待1秒
            Swal.fire('錯誤', '請先選擇門市。', 'error');
            setLoading(false);
            setCheckingConnections(false);
            setIsUploaderOpen(false);
            return;
        }else{
            setCheckProgress('驗證成功');
            setStore(props.storeName);
            await delay(1000); // 等待1秒


        }
        try {
            setCheckProgress('正在檢查伺服器連接...');
            await delay(1000); // 等待1秒

            // 檢查伺服器連接狀態
            const serverResponse = await axios.get(`https://inventory.edc-pws.com/api/checkConnections`);
            setServerConnected(serverResponse.data.serverConnected);
            setCheckProgress('通訊正常');
            console.log('Server status:', serverResponse.data.serverConnected);
        } catch (error) {
                setCheckProgress('失敗');
                Swal.fire('錯誤', '主伺服器器連接錯誤！', 'error');
                await delay(1000); // 等待1秒
                setLoading(false);
                setCheckingConnections(false);
                setIsUploaderOpen(false);
            return;
        }
            await delay(1000); // 等待1秒

        try {
            setCheckProgress('正在檢查 EPOS 主機連接...');
            await delay(1000); // 再次等待1秒

            // 檢查 EPOS 主機連接狀態
            const eposResponse = await axios.get(`https://inventory.edc-pws.com/api/ping`);
            setCheckProgress('通訊正常');
            setEposConnected(eposResponse.data.eposConnected);
            console.log('EPOS status:', eposResponse.data.eposConnected);
        } catch (error) {
                setCheckProgress('失敗');
                await delay(1000); // 等待1秒
                Swal.fire('錯誤', 'E-POS伺服器器連接錯誤！', 'error');
                setLoading(false);
                setCheckingConnections(false);
                setIsUploaderOpen(false);
            return;
        } 
        await delay(1000); // 等待1秒     
        setCheckingConnections(false);
        
       
        try {
                    const endpoint = props.storeName === 'dcz00' 
            ? `https://inventory.edc-pws.com/api/testInventoryTemplate/${props.storeName}`
            : `https://inventory.edc-pws.com/api/startInventory/${props.storeName}`;
        console.log(endpoint)
        const response = await axios.get(endpoint);

            setFirstNewProducts(response.data);
            setCompletedProducts(response.data.map(product => ({
                ...product,
                廠商: product.廠商,
                庫別: product.庫別
            })));

            setLoading(false);

        } catch (error) {
            Swal.fire('錯誤', '取得盤點模板時發生錯誤！', 'error');

        } finally {
            await delay(1000); // 等待1秒
            setLoading(false);
            setIsUploaderOpen(false);

        }
    };


        // 處理用戶輸入變更
  const handleInputChange = (index, field, value) => {
        setCompletedProducts(prev => {
            const updatedProducts = [...prev];
            updatedProducts[index][field] = value;
            return updatedProducts;
        });
    };

    const toggleSelectProduct = (index) => {
        setSelectedProducts(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    const toggleSelectAll = (e) => {
        const newSelections = {};
        firstNewProducts.forEach((_, index) => {
            newSelections[index] = e.target.checked; // 切换选择状态
        });
        setSelectedProducts(newSelections);
    };
    const columns = [
        {
            title: <Checkbox onChange={toggleSelectAll} />,
            dataIndex: 'Select ',
            render: (_, __, index) => (
                <Checkbox
                    checked={!!selectedProducts[index]}
                    onChange={() => toggleSelectProduct(index)}
                />
            ),
            width: '25px',
            align: 'left',

        },
        {
            title: '商品編號',
            key: '商品編號',
            dataIndex: '商品編號',
            width: '15%',
            align: 'center',

        },
        {
            title: '商品名稱',
            dataIndex: '商品名稱',
            key: '商品名稱',
            ellipsis: true,
            width: '30',
           
        },
        {
            title: '廠商',
            render: (_, __, index) => (
                <Select
                    value={completedProducts[index]?.廠商 || '待設定'}
                    onChange={value => {
                        handleInputChange(index, '廠商', value);

                        // 根據選擇的廠商自動設置庫別
                        const defaultLayer = value === '王座(用)' ? '備品' :
                            value === '王座(食)' ? '常溫' :
                                '待設定';
                        handleInputChange(index, '庫別', defaultLayer); // 自動設置庫別
                    }}
                    disabled={!selectedProducts[index]}
                    style={{ width: '100%' }}
                >
                    <Option value="待設定" disabled>選擇廠商</Option>
                    {props.allVendors.map(vendor => (
                        <Option key={vendor} value={vendor}>{vendor}</Option>
                    ))}
                </Select>
            ),
            width: '125px',
            align: 'center',
        },
        {
            title: '庫別',
            render: (_, __, index) => {
                const selectedVendor = completedProducts[index]?.廠商 || '待設定';
                const defaultLayer = selectedVendor === '王座(用)' ? '備品' :
                    selectedVendor === '王座(食)' ? '常溫' :
                        '待設定'; // 根據廠商的自動選擇

                return (
                    <Select
                        value={completedProducts[index]?.庫別 || defaultLayer} // 使用狀態管理的值或默認值
                        onChange={value => handleInputChange(index, '庫別', value)}
                        disabled={!selectedProducts[index]}
                        style={{ width: '100%' }}
                    >
                        <Option value="待設定" disabled>選擇庫別</Option>
                        {props.allLayers.map(layer => (
                            <Option key={layer} value={layer}>{layer}</Option>
                        ))}
                    </Select>
                );
            },
            width: '125px',
            align: 'center',
        },
    ];
    
    const phonecolumns = [
        {
            title: <Checkbox onChange={toggleSelectAll} />,
            dataIndex: 'Select ',
            render: (_, __, index) => (
                <Checkbox
                    checked={!!selectedProducts[index]}
                    onChange={() => toggleSelectProduct(index)}
                />
            ),
            width: '35px',
            align: 'left',

        },
        {
            title: '商品名稱',
            dataIndex: '商品名稱',
            key: '商品名稱',
           
        },
        {
            title: '廠商/庫別',
            render: (_, __, index) => (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Select
                        value={completedProducts[index]?.廠商 || '待設定'}
                        onChange={value => {
                            // 當用戶選擇廠商時更新廠商和庫別
                            handleInputChange(index, '廠商', value);
                            // 根據選擇的廠商自動更新庫別
                            const newLayer = value === '王座(用)' ? '備品' : value === '王座(食)' ? '常溫' : '待設定';
                            handleInputChange(index, '庫別', newLayer); // 自動設置庫別
                        }}
                        disabled={!selectedProducts[index]}
                        style={{ width: '100%' }}
                    >
                        <Option value="待設定" disabled>選擇廠商</Option>
                        {props.allVendors.map(vendor => (
                            <Option key={vendor} value={vendor}>{vendor}</Option>
                        ))}
                    </Select>

                    <Select
                        value={completedProducts[index]?.庫別 || '待設定'}
                        onChange={value => handleInputChange(index, '庫別', value)}
                        disabled={!selectedProducts[index]}
                        style={{ width: '100%' }}
                    >
                        <Option value="待設定" disabled>選擇庫別</Option>
                        {props.allLayers.map(layer => (
                            <Option key={layer} value={layer}>{layer}</Option>
                        ))}
                    </Select>
                </div>
            ),
            align: 'center',
        },


    ];

    const saveCompletedProducts = async () => {

        const preparedProducts = completedProducts.map((product, index) => ({
            ...product,
            廠商: selectedProducts[index] ? product.廠商 : '未使用',
            庫別: selectedProducts[index] ? product.庫別 : '未使用',
        }));

        try {
            await axios.post(`https://inventory.edc-pws.com/api/saveCompletedProducts/${props.storeName}`, preparedProducts);
            Swal.fire('成功', '數據保存成功！正在刷新盤點數據。', 'success');
                props.fetchProducts();
                setCompletedProducts([]);
                setFirstNewProducts([]);
        } catch (error) {
            Swal.fire('錯誤', '產品數據保存失敗！', 'error');
        } finally {
            await delay(1000); // 等待1秒
            setLoading(false);
            }
    };
    return (
        <>
            {loading && <Spin fullscreen />}

            {firstNewProducts.length === 0 && isUploaderOpen ? <Spin fullscreen /> :
                <Modal title="新品列表" width={'auto'} maskClosable={false} keyboard={false} mask={true} centered={true} open={firstNewProducts.length > 0} onCancel={() => setFirstNewProducts([])} onOk={saveCompletedProducts} closable={false} okText="提交">
                    <p>本期共新增 {firstNewProducts.length} 項新品，請勾選門市有使用的品項並選擇其廠商、庫別</p>
                    {window.innerWidth <= 600 ? (
                        <div style={{ width: '100%', marginBottom: '10px' }} className='行動裝置'>
                            <Table
                                dataSource={firstNewProducts}
                                columns={phonecolumns}
                                pagination={true}
                                rowKey={(record, index) => index} // 使用索引作為 key
                                scroll={{ y: 450 }} // 設定 x 軸的最大寬度
                                loading={loading} // 這裡設置載入狀態
                            />
                        </div>
                    ) : (
                        <div className='大型顯示器' style={{ minWidth: 'calc(100% - 30%)', maxWidth: '750px', margin: '0 auto', overflowX: 'auto' }}>
                            <Table
                                dataSource={firstNewProducts}
                                columns={columns}
                                pagination={true}
                                rowKey={(record, index) => index} // 使用索引作為 key
                                size="small"
                                scroll={{ y: 400 }}  // 設定垂直滾動
                                loading={loading} // 這裡設置載入狀態
                            />
                        </div>
                    )}
                </Modal>}

            <Modal
                footer={null} // 不使用預設的 footer
                title=
                {<div style={{textAlign: 'center'}}> 連線檢查進度</div>}
                open={checkingConnections}
                maskClosable={false}
                keyboard={false}
                mask={true}
                centered={true}
                closable={false}
            >
                <div style={{ alignItems: 'center', textAlign: 'center'}}>
                        <p>{checkProgress}</p>
                        <p>門市：{store || '檢查中...'}</p>
                        <p>伺服器：{serverConnected === null ? '檢查中...' : (serverConnected ? '在線' : '離線')}</p>
                        <p>EPOS 主機：{eposConnected === null ? '檢查中...' : (eposConnected ? '在線' : '離線')}</p>
               </div>
                        </Modal>
            
                <Modal
                    isOpen={isModalOpen}
                    title={modalContent.title}
                    message={modalContent.message}
                    type={modalContent.type}
                onClose={() => setIsModalOpen(false)}

                />
                <ConfirmModal
                    isOpen={isConfirmModalOpen}
                    title={confirmModalContent.title}
                    message={confirmModalContent.message}
                    onClose={() => setIsConfirmModalOpen(false)}
                    onConfirm={() => {
                        if (modalAction) {
                            modalAction();
                            setModalAction(null);
                        }
                    }}
                />
            </>

       );
});

export default InventoryUploader;