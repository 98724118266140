// StoreSelection.js
import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const StoreSelection = ({ storeMappings, selectedStore, onStoreChange }) => {
    return (
        <Select
            value={selectedStore || ''} // 使用当前选中的门市
            onChange={onStoreChange}
            style={{ width: '120px', border: 'null' }}
        >
            <Option value="" disabled>請選擇門市</Option> {/* 默认提示 */}
            {Object.keys(storeMappings).map(storeName => (
                <Option key={storeMappings[storeName]} value={storeMappings[storeName]}>
                    {storeName} {/* 显示门市名称 */}
                </Option>
            ))}
        </Select>
    );
};

export default StoreSelection;
