// app.js
import React, { useEffect, useState, useRef } from 'react';
import { Footer, Menu, message, Empty, Popconfirm, Spin, Layout, Checkbox, Table, Result, Col, Divider, Row, Popover, Flex, between, Select, notification, Typography, Progress, InputNumber, Drawer, DatePicker, Input, Space, Button } from 'antd';
import { SearchOutlined, MenuOutlined, FileDoneOutlined, DeleteOutlined, CloseOutlined, LoadingOutlined, BarsOutlined, QuestionCircleOutlined, SignatureOutlined, DownloadOutlined, PoweroffOutlined } from '@ant-design/icons';

import axios from 'axios';
import { io } from 'socket.io-client';
import { Analytics } from '@vercel/analytics/react';

import Swal from 'sweetalert2'
import ExportModal from './components/ExportModal';
import ArchiveModal from './components/ArchiveModal';
import GuideModal from './components/GuideModal';
import Modal from './components/Modal';
import Calculator from './components/Calculator';
import TestComponent from './components/TestComponent';
import ProductInput from './components/ProductInput';
import InventoryUploader from './components/InventoryUploader';
import InventoryItemsManager from './components/InventoryItemsManager';
import dayjs from 'dayjs';
import Announcement from './components/Announcement';
import StoreSelection from './components/StoreSelection'; // 引入门市选择组件


import { setCookie, getCookie } from './utils/cookie';
import './components/style/Modal.css'; // 導入 Modal 的 CSS 樣式
import './components/style/Calculator.css'; // 導入 Modal 的 CSS 樣式'
const { Text, Link } = Typography;

const apiUrl = process.env.REACT_APP_API_UR;
//import dayjs from 'dayjs';
const STORE_ROOM_TIMEOUT = 10 * 60 * 1000; // 10分钟超时

const storeMappings = {
    '新店京站': 'dcz18',
    '台北京站': 'dcz05',
    '信義威秀': 'dcz03',
    '測試門市': 'dcz00',
};
const allVendors = ['全台', '央廚', '王座(用)', '王座(食)', '忠欣', '開元', '裕賀', '美食家', '點線麵'];
const allLayers = ['冷藏', '冷凍', '常温', '清潔', '備品'];
const items = [
    { key: '1', icon: <SignatureOutlined />, label: '品項管理' },
    { key: '2', icon: < PoweroffOutlined />, label: '盤點開始' },
    { key: '3', icon: <DownloadOutlined />, label: '匯出' },
    { key: '4', icon: <QuestionCircleOutlined />, label: '說明' },
];
const App = () => {
    const socket = useRef(null); // 使用 useRef 來持久化套接字連接
    const tableRef = useRef(null);
    const inputNumberRefs = useRef([]);
    const rowRefs = useRef([]); // 添加对行的引用
    const { Option } = Select;
    const [collapsed, setCollapsed] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isAsk, setIsAsk] = useState(false);

    // 狀態變數
    const [archivePassword, setArchivePassword] = useState(''); // 存儲歸檔密碼
    const [hasProducts, setHasProducts] = useState(false); // 新增狀態變數來追蹤產品列表是否存在
    const [columns, setColumns] = useState([]);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [selectedStore, setSelectedStore] = useState(''); // 当前选择的门市

    const [searchTerm, setSearchTerm] = useState('');
    const [searchVisible, setSearchVisible] = useState(false);
    const [filterStatus, setFilterStatus] = useState('全部品項'); // 初始状态为所有品项
    const [showFunctionButtons, setShowFunctionButtons] = useState(false);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [connectionStatus, setConnectionStatus] = useState('連線中...');
    const [showGuide, setShowGuide] = useState(false);
    const [isOffline, setIsOffline] = useState(false);
    const [errorModal, setErrorModal] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isCalculatorMode, setIsCalculatorMode] = useState(false); // 初始狀態
    const [isStoreChooseModalOpen, setIsStoreChooseModalOpen] = useState(false);

    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [isInventoryItemsManagerOpen, setIsInventoryItemsManagerOpen] = useState(false);
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const cookieName = 'inventoryGuideShown';
    const [isReconnectPromptVisible, setIsReconnectPromptVisible] = useState(false);
    const inputRefs = useRef([]);
    const [hoveredProduct, setHoveredProduct] = useState(null);
    const [initialStock, setInitialStock] = useState(''); // 用於存儲期初庫存
    const [currentSpec, setCurrentSpec] = useState(''); // 用於存儲規格
    const [currentunit, setCurrentunit] = useState(''); // 用於存儲單位
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [modalContent, setModalContent] = useState({});
    const [userCount, setUserCount] = useState(0);
    const archiveModalRef = useRef();
    const inventoryUploaderRef = useRef();
    const [storeName, setStoreName] = useState('');
    const [showCalculator, setShowCalculator] = useState(false);

    const [uploadStatus, setUploadStatus] = useState(null); // 存储上传状态
    const [storeChangeTimeout, setStoreChangeTimeout] = useState(null);

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const [vendorStatistics, setVendorStatistics] = useState({}); // 儲存廠商統計
    const disabledVendors = ['忠欣', '王座(備)'];
    const [isMobile, setIsMobile] = useState(false);
    const timerRef = useRef(null); // 存储计时器 ID
    const [currentProductIndex, setCurrentProductIndex] = useState(null);
    const [calculatorVisible, setCalculatorVisible] = useState(true);
    const [currentProductCode, setCurrentProductCode] = useState(null);
    const [currentProductName, setCurrentProductName] = useState(''); // 新增状态来存储商品名称
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [selectedLayers, setSelectedLayers] = useState([]);
    const expiryDateInputRefs = useRef([]); // 到期日輸入框的引用
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        }
    });
    const [api, contextHolder] = notification.useNotification();
    const [messageApi, messageHolder] = message.useMessage();

    const [open, setOpen] = useState(false);


    //發布公告用
    const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
    const [isAnnouncementModalVisible, setIsAnnouncementModalVisible] = useState(false);
    const [reconnectInterval, setReconnectInterval] = useState(null);

    /*const fetchCsrfToken = async () => {
    try {
        const response = await axios.get('${apiUrl}form');
        return response.data.csrfToken; // 返回獲取的 CSRF 令牌
    } catch (error) {
        console.error('Failed to fetch CSRF token:', error);
        return null;
    }
};*/
    // 產品數據加載函數

    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [warning, setWarning] = useState('');


    // 检测设备类型
    const checkDeviceType = () => {
        setIsMobile(window.innerWidth <= 768); // 768px 以下为移动设备
    };

    useEffect(() => {
        checkDeviceType();
        window.addEventListener('resize', checkDeviceType); // 监听窗口大小变化

        return () => {
            window.removeEventListener('resize', checkDeviceType); // 清理监听
        };
    }, []);


    useEffect(() => {

        const connectSocket = () => {
            socket.current = io('https://inventory.edc-pws.com'); // 使用正确的服务器地址
            // 监听连接事件
            const key = 'updatable';

            socket.current.on('connect', () => {
                console.log('已连接到 Socket.IO 服务器');
                setIsOffline(false);
                setIsReconnectPromptVisible(false);

               

                // 清除重连定时器
                if (reconnectInterval) {
                    clearInterval(reconnectInterval);
                    setReconnectInterval(null);
                }
            });
            // 監聽斷開連線事件
            socket.current.on('disconnect', () => {

                console.log('Socket.IO 斷開連接');
                setIsOffline(true);
                setIsReconnectPromptVisible(true);

                notification.warning({
                    key,
                    message: '連線中斷',
                    description: '您已失去連接，請檢查網路狀態。 ',
                });

                // 如果沒有重連定時器則設定一個
                if (!reconnectInterval) {
                    const intervalId = setInterval(() => {
                        console.log('嘗試重新連線...');
                        connectSocket(); // 嘗試重新連接
                    }, 60000); // 每60秒重連一次
                    setReconnectInterval(intervalId); // 儲存計時器ID
                }
            });
        };

        connectSocket(); // 初始化連接

        // 清理函數
        return () => {
            if (reconnectInterval) {
                clearInterval(reconnectInterval); // 清除重連計時器
            }
            if (socket.current) {
                socket.current.disconnect(); // 斷開連接
            }
        };
    }, [reconnectInterval]);
    useEffect(() => {
        // 设置 Socket.IO 监听器
        socket.current.on('newAnnouncement', (data) => {
            notification.info({
                message: '新通知',
                description: data.message,
                duration: 10, // 显示时间（秒）
            });
        });

        // 清理监听器
        return () => {
            socket.current.off('newAnnouncement');
        };
    }, []);

    useEffect(() => {
        if (success) {
            message.success(success);
        }
    }, [success]); // 只在结果或错误变化时触发
    useEffect(() => {
        if (error) {
            message.error(error);
        }
    }, [error]); // 只在结果或错误变化时触发
    useEffect(() => {
        if (warning) {
            message.warning(warning);
        }
    }, [warning]); // 只在结果或错误变化时触发

    // 初始选择界面
    const selectStore = (value) => {
        setStoreName(value); // 直接设置选中的 value

    };

    const handleStoreChange = (newStoreName) => {
        if (selectedStore) {
            socket.current.emit('leaveStoreRoom', selectedStore); // 离开当前房间
        }

        if (newStoreName) {
            socket.current.emit('joinStoreRoom', newStoreName); // 加入新房间
            setSelectedStore(newStoreName); // 更新当前房间
        }
        fetchProducts(storeName);
    };

    // 获取店名
    const getStoreNameByCode = (code) => {
        return Object.keys(storeMappings).find(key => storeMappings[key] === code);
    };

    const fetchProducts = async () => {
        if (storeName === 'NA') { return; } // 如果门市不合法，则返回
        setLoading(true);
        try {
            const response = await axios.get(`https://inventory.edc-pws.com/api/products/${storeName}`);
            if (response.status === 200) {
                setProducts(response.data);
                setHasProducts(true);
                setIsOffline(false);
            } else {
                console.warn('Unexpected response status:', response.status);
            }
        } catch (error) {
            console.error('取得產品失敗:', error);
            setIsOffline(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (storeName) {
            handleStoreChange(storeName);
            fetchProducts(storeName); // 在 storeName 或 isCalculatorMode 变化时调用
        }

        socket.current.on('updateUserCount', setUserCount);

        return () => {
            socket.current.off('updateUserCount', setUserCount);
            if (selectedStore) {
                socket.current.emit('leaveStoreRoom', selectedStore); // 离开当前房间
            }
        };
    }, [storeName, isCalculatorMode]); // 添加 isCalculatorMode 作为依赖项


    useEffect(() => {
        const guideShown = getCookie(cookieName); // 檢查 cookie 是否存在
        if (!guideShown) {
            // 如果 cookie 不存在，顯示說明手冊
            setTimeout(() => {
                setShowGuide(true);
                setCookie(cookieName, 'true'); // 設定 cookie，防止再顯示
            }, 1000);
        }

        // Socket.IO 事件監聽
        socket.current.on('updateUserCount', setUserCount);


        const updateColumns = () => {
            if (window.innerWidth <= 600) {
                // 如果窗口宽度小于或等于 768 像素，则为移动设备
                setColumns(mobileColumns);
            } else {
                setColumns(desktopColumns);
            }
        };

        window.addEventListener('resize', updateColumns); // 监听窗口大小变化

        if (products.length > 0) {
            const vendorTotals = calculateVendorTotals();
            setVendorStatistics(vendorTotals);
        }
        updateColumns();



        // 網路連線狀態監聽 (需要根據瀏覽器環境調整)
        const handleOnline = () => {
            Swal.fire('通知', '連線成功', 'success');
            setIsOffline(false);
        };
        const handleOffline = () => {
            Swal.fire('通知', '失去連線', 'warning');
            setIsOffline(true);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // 清除函式
        return () => {
            socket.current.off('updateUserCount', setUserCount);
            window.removeEventListener('resize', updateColumns); // 监听窗口大小变化
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, [storeName, isCalculatorMode]); // 將 setHasProducts 添加到依賴項中

    useEffect(() => {
        const key = 'update';

        // Socket.IO 监听产品更新事件
        socket.current.on('productUpdated', (updatedProduct) => {
            setProducts(prevProducts =>
                prevProducts.map(product =>
                    product.商品編號 === updatedProduct.商品編號 ? updatedProduct : product
                )
            );
            message.success(`${updatedProduct.商品名稱} 數量變更為 ${updatedProduct.數量}`)
           
        });
        socket.current.on('productExpiryDateUpdated', (updatedProduct) => {
            setProducts(prevProducts =>
                prevProducts.map(product =>
                    product.商品編號 === updatedProduct.商品編號 ? updatedProduct : product
                )
            );
            message.success(`${updatedProduct.商品名稱} 到期日變更為 ${updatedProduct.到期日}`)
           
        });
        socket.current.on('productDepotUpdatedV', (updatedProduct) => {
            setProducts(prevProducts =>
                prevProducts.map(product =>
                    product.商品編號 === updatedProduct.商品編號 ? updatedProduct : product
                )
            );
            // 顯示警告消息並提供恢復選項
            const productCode = updatedProduct.商品編號;  

            const messageContent = `${updatedProduct.商品名稱} 已設為停用`;


            const restoreButton = (
                <Button
                    type="link"
                    onClick={() => {
                        handleDepotChange(productCode, false); // 恢復產品
                    }}
                >
                    復原
                </Button>
            );

            const updatedContent = (
                <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%', zIndex: '1000' }}>
                    {messageContent}
                    {restoreButton}
                </span>
            );

            messageApi.open({
                key,
                type: 'warning',
                content: updatedContent,
                duration: 8,
            });
        });

            socket.current.on('productDepotUpdatedX', (updatedProduct) => {
                setProducts(prevProducts =>
                    prevProducts.map(product =>
                        product.商品編號 === updatedProduct.商品編號 ? updatedProduct : product
                    )
                );
                // 顯示警告消息並提供恢復選項
                messageApi.open({
                    key,
                    type: 'success',
                    content: `${updatedProduct.商品名稱} 已恢復使用 `,
                    duration: 3,

                });
            });
        // 清理事件监听器
        return () => {
            socket.current.off('productUpdated');
            socket.current.off('productExpiryDateUpdated');
            socket.current.off('productDepotUpdatedV');
            socket.current.off('productDepotUpdatedX');
        };
    }, []);



    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };

    const handleMenuClick = (key) => {
        // 处理菜单项的点击事件
        console.log(`Clicked menu item: ${key}`);
        setDrawerVisible(false); // 关闭 Drawer
    };

    const handleCollapse = (coll) => {
        setCollapsed(coll);
    };
    
    const handlePasswordSuccess = () => {
        setIsPasswordModalVisible(false); // 关闭密码模态框
        setIsAnnouncementModalVisible(true); // 打开公告组件模态框
    };

    const openInventoryItemsManager = () => {
        setIsInventoryItemsManagerOpen(true);
    };



    const startInventory = () => {
        if (inventoryUploaderRef.current) {
            inventoryUploaderRef.current.startInventory(); // 調用子組件的方法
        }
    };
    const toggleMenu = () => {
        setIsMenuOpen((prevState) => !prevState);
    };


    // 錯誤處理函式
    const handleError = (error, defaultMessage) => {
        const errorMessage = error.response ? error.response.data.message || error.response.data : error.message || defaultMessage;
        setErrorModal({ title: '錯誤訊息', message: errorMessage });
        setIsModalOpen(true);
    };


    const handleReconnect = () => {
        setConnectionStatus('連接成功 ✔');
        setIsUserOffline(false);
        setIsReconnectPromptVisible(false);

    };

    const handleReload = () => {
        window.location.reload(); // 重新加載頁面
        setIsModalOpen(false);
    };
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const calculateVendorTotals = () => {
        const filteredProducts = products.filter(product => product.庫別 !== '未使用');

        // 使用 reduce 來按廠商分組
        const vendorTotals = filteredProducts.reduce((acc, product) => {
            const vendor = product.廠商;

            if (!acc[vendor]) {
                acc[vendor] = { totalValid: 0, totalWithQuantities: 0 };
            }

            acc[vendor].totalValid += 1;
            if (product.數量 > 0) {
                acc[vendor].totalWithQuantities += 1;
            }

            return acc;
        }, {});

        // 計算每個廠商的百分比
        for (const vendor in vendorTotals) {
            const { totalValid, totalWithQuantities } = vendorTotals[vendor];
            vendorTotals[vendor].percentage = totalValid > 0 ? ((totalWithQuantities / totalValid) * 100).toFixed(2) : 0;
        }

        return vendorTotals;
    };
    // 控制廠商篩選
    const handleVendorChange = (vendor) => {
        setSelectedVendors((prev) =>
            prev.includes(vendor) ? prev.filter(v => v !== vendor) : [...prev, vendor]
        );
    };

    // 控制庫別篩選
    const handleLayerChange = (layer) => {
        setSelectedLayers((prev) =>
            prev.includes(layer) ? prev.filter(l => l !== layer) : [...prev, layer]
        );
    };



    //下載最新校期
    const updateExpiryDate = async (productCode, expiryDate) => {
        try {
            await axios.put(`https://inventory.edc-pws.com/api/products/${storeName}/${productCode}/expiryDate`, { 到期日: expiryDate });
        } catch (error) {
            console.error("更新產品到期日時發生錯誤:", error);
        }
    };
    //下載最新庫別
    const updateDepotDate = async (productCode, depot) => {
        try {
            await axios.put(`https://inventory.edc-pws.com/api/products/${storeName}/${productCode}/depot`, { 停用: depot });
        } catch (error) {
            console.error("更新產品庫別時發生錯誤:", error);
        }
    };

    //下載最新數量
    const updateQuantity = async (productCode, quantity) => {
        try {
            const response = await axios.put(`https://inventory.edc-pws.com/api/products/${storeName}/${productCode}/quantity`, { 數量: quantity });

            return response.data; // 返回成功的响应数据

        } catch (error) {

            console.error("更新產品數量時發生錯誤:", error);
            throw new Error("更新產品數量失敗"); // 抛出错误以便在调用时被捕获
        }
    };


    //上傳數量


    const handleQuantityChange = async (productCode, quantity) => {
        const numQuantity = Number(quantity);

        // 输入验证
        if (numQuantity < 0) {
            alert('數量必須為非負數');
            return Promise.reject(new Error('數量必須為非負數')); // Reject promise
            return;

        } else if (isNaN(numQuantity)) {
            return;
        }

        // 更新产品列表中的数量
        const updatedProducts = products.map(product =>
            product.商品編號 === productCode ? { ...product, 數量: numQuantity } : product
        );

        //updateQuantity(productCode, numQuantity);
        // setProducts(updatedProducts);  // 更新产品状态


        try {
            await updateQuantity(productCode, numQuantity); // 确保 updateQuantity 是返回 Promise 的
            return Promise.resolve(); // 成功时返回 Promise
            setProducts(updatedProducts);
        } catch (error) {
            console.error("更新產品數量時出錯！:", error);
            return Promise.reject(error); // Reject promise on error
        }
    };




    // 上傳到期日
    const handleExpiryDateChange = (productCode, expiryDate) => {
        const updatedProducts = products.map(product => {
            if (product.商品編號 === productCode) {
                return { ...product, 到期日: expiryDate }; // 更新到期日
            }
            return product;
        });
        //setProducts(updatedProducts);

        updateExpiryDate(productCode, expiryDate); // 上傳到期日到資料庫
    };

    // 上傳庫別
    const handleDepotChange = (productCode, depot) => {
        const updatedProducts = products.map(product => {
            if (product.商品編號 === productCode) {
                return { ...product, 停用: depot }; // 更新停用狀態
            }
            return product;
        });
        //setProducts(updatedProducts);

        updateDepotDate(productCode, depot); // 上傳停用狀態到資料庫
    };

    const handleMouseEnter = (product, e) => {
        setHoveredProduct(product.商品編號);
        // 直接使用 product 來設置初始庫存、規格和單位
        setInitialStock(product.期初庫存 ? `${product.期初庫存} ${product.單位}` : '查無歷史盤點紀錄');
        setCurrentSpec(product.規格 ? product.規格 : '未設定');
        setCurrentunit(product.單位 ? product.單位 : '');
        // 獲取當前鼠標位置
        const rect = e.currentTarget.getBoundingClientRect();
        setTooltipPosition({ top: e.clientY + 10, left: e.clientX + 10 });
    };

    const handleMouseLeave = () => {
        setHoveredProduct(null); // 清除懸停商品
        setInitialStock('');
        setCurrentSpec('');
        setCurrentunit('');
    };


    const openArchiveModal = () => {
        setIsArchiveModalOpen(true); // 打开模态框
    };

    const closeArchiveModal = () => {
        setIsArchiveModalOpen(false); // 关闭模态框
    };


    const toggleMode = () => {
        setIsCalculatorMode(prevMode => !prevMode); // 切換計算機模式
        fetchProducts()
    };


    const focusNextInput = (index) => {
        if (inputRefs.current[index + 1]) {
            inputRefs.current[index + 1].focus(); // 切换到下一个输入框
        }
    };


    const toggleFilter = (status) => {
        setFilterStatus(status); // 直接使用传入的状态
    };


    // 根据所选的厂商、库别和搜索关键词过滤产品
    const filteredProducts = products.filter(product => {
        const vendorMatch = selectedVendors.length === 0 || selectedVendors.includes(product.廠商); // 材商匹配
        const layerMatch = selectedLayers.length === 0 || selectedLayers.includes(product.庫別); // 库别匹配
        const matchesSearchTerm = product.商品名稱.includes(searchTerm); // 搜索关键词匹配

        // 根据过滤状态返回相应的过滤逻辑
        if (filterStatus === '全部品項') {
            return vendorMatch && layerMatch && matchesSearchTerm; // 所有条件都满足
        }
        if (filterStatus === '已盤點品項') {
            return product.數量 != null && vendorMatch && layerMatch && matchesSearchTerm; // 数量不为空且满足其他条件
        }
        if (filterStatus === '未盤點品項') {
            return product.數量 == null && vendorMatch && layerMatch && matchesSearchTerm; // 数量为空且满足其他条件
        }
        return false; // 默认情况下不返回任何结果
    });

    const handleCalculate = async (productCode, quantity) => {
        const numQuantity = Number(quantity);

        // 输入验证
        if (numQuantity < 0) {
            alert('數量必須為非負數');
            return Promise.reject(new Error('數量必須為非負數')); // Reject promise
        } else if (isNaN(numQuantity)) {
            return;
        }

        // 更新产品列表中的数量
        const updatedProducts = products.map(product =>
            product.商品編號 === productCode ? { ...product, 數量: numQuantity } : product
        );

        //updateQuantity(productCode, numQuantity);
        setProducts(updatedProducts);  // 更新产品状态


        try {
            await updateQuantity(productCode, numQuantity); // 确保 updateQuantity 是返回 Promise 的
            return Promise.resolve(); // 成功时返回 Promise
            setProducts(updatedProducts);
        } catch (error) {
            console.error("更新产品数量时出错:", error);
            return Promise.reject(error); // Reject promise on error
        }
    };


    const handleSearchClick = () => {
        if (searchVisible) {
            if (searchTerm) {
                // 如果輸入框內有文字，則僅清空
                setSearchTerm('');
            } else {
                // 如果沒有文字則關閉搜尋模式
                setSearchVisible(false);
            }
        } else {
            // 點擊放大鏡圖示則開啟搜尋模式
            setSearchVisible(true);
        }
    };

    const toggleProductStatus = (productCode) => {
        // Logic to change product status to "未使用"
        setProducts(prevProducts =>
            prevProducts.map(product =>
                product.商品編號 === productCode ? { ...product, 庫別: '未使用' } : product
            )
        );
    };

    const handleCheckboxChange = (productCode, index) => {
        // Change product status with a delay
        setTimeout(() => {
            toggleProductStatus(productCode);  // Call your toggle status function
        }, 5000);  // 5 seconds
    };


    const desktopColumns = [
        {
            title: '商品編號',
            dataIndex: '商品編號',
            key: '商品編號',
            width: '15%',  // 平板和電腦時的寬度
            minWidth: '116px',
            align: 'center',
            className: '編號',
        },
        {
            title: '商品名稱',
            dataIndex: '商品名稱',
            key: '商品名稱',
            width: '40%',
            className: '品名',

            render: (text, product) => (
                <div
                    onMouseEnter={(e) => handleMouseEnter(product, e)} // 悬停事件处理
                    onMouseLeave={handleMouseLeave} // 离开事件处理
                >
                    {text}
                </div>

            ),
        },
        {
            title: '數量',
            dataIndex: '數量',
            key: '數量',
            render: (_, product, index) => ( // 使用 _ 来忽略第一个参数
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    {isCalculatorMode && (
                        <Popover color='#696969' boxShadowSecondary='rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                            content={
                                <Calculator
                                    onCalculate={(result) => {
                                        //updateQuantity(product.商品編號, result);
                                        handleCalculate(product.商品編號, result)
                                        //handleQuantityChange(, result);
                                        setCurrentProductCode(product.商品編號);
                                    }}
                                    onClose={() => setShowCalculator(false)} // 关闭计算器
                                    productName={product.商品名稱}
                                    currentIndex={index}
                                />
                            }
                            title=""
                            trigger="hover"
                            onOpenChange={(visible) => {
                                if (!visible) {
                                    setShowCalculator(false);
                                }
                            }}
                        >
                            <Button
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    borderColor: '#d9d9d900',
                                    backgroundColor: 'rgba(255, 255, 255, 0)', // 透明背景
                                    zIndex: 999,
                                }}
                                tabIndex={0}
                            />
                        </Popover>
                    )}

                    <ProductInput
                        key={product.商品編號}
                        product={product}
                        index={index}
                        isCalculatorMode={isCalculatorMode}
                        handleQuantityChange={handleQuantityChange} // 传递处理函数 
                        handleDepotChange={handleDepotChange} // 传递处理函数


                        inputRefs={inputRefs} // 传递 refs 以便在 ProductInput 中访问
                        focusNextInput={focusNextInput} // 传递切换输入框的函数
                        updateQuantity={handleQuantityChange} // 用于更新数量
                        toggleProductStatus={toggleProductStatus}
                    />


                </div>
            ),
            width: '15%',
            align: 'center',
        },
        {
            title: '到期日',
            dataIndex: '到期日',
            key: '到期日',
            render: (text, product, index) => {
                const isDisabled = disabledVendors.includes(product.廠商); // 检查厂商是否在禁用数组中
                return (

                    <DatePicker
                        defaultValue={product.到期日 ? dayjs(product.到期日) : null}
                        onChange={(date, dateString) => handleExpiryDateChange(product.商品編號, dateString)}
                        style={{ width: '110px' }}
                        ref={el => (expiryDateInputRefs.current[index] = el)}
                        disabled={isDisabled} // 禁用逻辑
                        format="YYYY-MM-DD"
                    />
                );

            },
            width: '22%', // 設定到期日的寬度
            minWidth: '165px',

            align: 'center',
        },
    ];

    const mobileColumns = [
        {
            title:
                <Flex justify='space-between' align='center'>
                    {!searchVisible ? (<>商品名稱</>) : (
                        <Input
                            placeholder="搜尋商品名稱..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} // 更新搜尋詞
                            style={{ transition: 'all  ease-in-out', width: 200 }}
                            allowClear
                        />
                    )}

                    <Button onClick={handleSearchClick} style={{ display: 'flex', textAlign: 'right' }}>
                        {searchVisible ? <CloseOutlined /> : <SearchOutlined />}
                    </Button>
                </Flex>,

            dataIndex: '商品名稱',
            key: '商品名稱',
            width: '50%',
            className: '品名',
            render: (text, product) => (
                <div
                    onMouseEnter={(e) => handleMouseEnter(product, e)} // 悬停事件处理
                    onMouseLeave={handleMouseLeave} // 离开事件处理
                >
                    {text}
                </div>
            ),
        },
        {
            title: '數量',
            dataIndex: '數量',
            key: '數量',
            render: (_, product, index) => ( // 使用 _ 来忽略第一个参数
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                    {isCalculatorMode && (
                        <Popover
                            color='#696969'
                            boxShadowSecondary='rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                            content={
                                <Calculator
                                    onCalculate={(result) => {
                                        updateQuantity(product.商品編號, result);
                                        setCurrentProductCode(product.商品編號);
                                    }}
                                    onClose={() => setShowCalculator(false)}
                                    productName={product.商品名稱}
                                    currentIndex={index}
                                />
                            }
                            title=""
                            trigger="hover"
                            onOpenChange={(visible) => {
                                if (!visible) {
                                    setShowCalculator(false);
                                }
                            }}
                        >
                            <Button
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    borderColor: '#d9d9d900',
                                    backgroundColor: 'rgba(255, 255, 255, 0)',
                                    zIndex: 999,
                                }}
                                tabIndex={0}
                            />
                        </Popover>
                    )}

                    <ProductInput
                        key={product.商品編號}
                        product={product}
                        setIsAsk={setIsAsk(true)}
                        index={index}
                        isCalculatorMode={isCalculatorMode}
                        handleQuantityChange={handleQuantityChange}
                        handleDepotChange={handleDepotChange}
                        inputRefs={inputRefs}
                        focusNextInput={focusNextInput}
                    />
                </div>

            ),
            width: '20%',
            align: 'center',
        },

        {
            title: '到期日',
            dataIndex: '到期日',
            key: '到期日',
            render: (text, product, index) => {
                const isDisabled = disabledVendors.includes(product.廠商); // 检查厂商是否在禁用数组中

                return (

                    <DatePicker
                        defaultValue={product.到期日 ? dayjs(product.到期日) : null}
                        onChange={(date, dateString) => handleExpiryDateChange(product.商品編號, dateString)}
                        ref={el => (expiryDateInputRefs.current[index] = el)}
                        disabled={isDisabled} // 禁用逻辑
                        format="YYYY-MM-DD"
                    />
                );

            },
            width: '30%',
            align: 'center',
        },
    ];
    const toggleButton = (
        <Button type="primary" onClick={startInventory}>
            開始盤點
        </Button>
    );

    return (
        <div>
            {!storeName ? (
                // 选择界面
                <div style={{
                    display: 'flex',
                    justifyContent: 'center', // 水平居中
                    alignItems: 'center', // 垂直居中
                    padding: '20px', // 可選的內邊距
                }}>
                <div className='modal-content' >
                    <h3>請選擇門市
                        <Select style={{ width: '200px' }} onChange={selectStore}>
                            <Option value=''>請選擇門市</Option>
                            {Object.keys(storeMappings).map(storeName => (
                                <Option key={storeMappings[storeName]} value={storeMappings[storeName]}>
                                    {storeName} {/* 显示门市名称 */}
                                </Option>

                            ))}
                        </Select>
                        </h3>
                    </div>

                </div>
            ) : (
                    <Layout  style={{
                        justifyContent: 'center', width: '750px' ,
                    display: 'flex', alignItems: 'center'
                }}>
                        {loading && <Spin fullscreen />}
                        <Layout.Header style={{
                            borderRadius: '10px', margin: '0 auto', width: '100%',
} }>
                            <Row style={{ width: '100%', alignItems: 'center', zIndex: '1000'}}>
                                <Col flex={isMobile ? "4" : "2"} style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                                    <span>
                                        <Text className="big" style={{ marginLeft: '6px', fontSize: '20px', fontWeight: 'bold' }}>
                                            庫存盤點系
                                        </Text>
                                        <Text className="big" onClick={() => setIsAnnouncementModalVisible(true)} style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                            統
                                        </Text>
                                    </span>
                                </Col>
                                <Col flex={isMobile ? "1" : "3"} style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
                                    {isMobile ? (
                                        <>
                                            <Button
                                                style={{
                                                    position: 'absolute', // 靠近页面上方
                                                        right: '0', // 锚定到右边缘
                                                        borderColor: 'rgba(255, 255, 255, 0)', background: 'rgba(255, 255, 255, 0)' }}
                                                onClick={toggleMenu}
                                            >
                                                <BarsOutlined style={{ fontSize: 'xx-large', color: '#ffffff' }} />
                                            </Button>

                                           
                                                             {isMenuOpen && (
                                                        <Menu
                                                            theme="dark"
                                                            mode="inline"
                                                    style={{
                                                        
                                                        position: 'absolute', // 靠近页面上方
                                                        top: '20px', // 从顶部微调位置
                                                        zIndex: 1000, // 确保在其他元素之上
                                                        width: 'auto', // 根据内容自动调整宽度
                                                         borderRadius: '10px', /* 设置圆角 */
                                                            }}
                                                        >

                                                    {products.length !== 0 ? (
                                                        <>
                                                            <Menu.Item key="item1">
                                                                <Button onClick={openInventoryItemsManager}>項目管理</Button>
                                                            </Menu.Item>
                                                            <Menu.Item key="item2">
                                                                <Button className="header-button" onClick={() => setIsExportModalOpen(true)} type="default">匯出</Button>
                                                            </Menu.Item>
                                                            <Menu.Item key="item3">
                                                                <Button className="header-button" onClick={() => setShowGuide(true)} type="default">說明</Button>
                                                            </Menu.Item>
                                                            <Menu.Item key="item4">
                                                                <Button danger onClick={openArchiveModal} type="primary">盤點結束</Button>
                                                            </Menu.Item>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Menu.Item key="item5">
                                                                <Button onClick={startInventory} type="primary">盤點開始</Button>
                                                            </Menu.Item>
                                                            <Menu.Item key="item6">
                                                                <Button className="header-button" onClick={() => setShowGuide(true)} type="default">說明</Button>
                                                            </Menu.Item>
                                                        </>
                                                    )}
                                                </Menu>
                                            )}
                                        </>
                                    ) : (
                                        // 在桌面设备上的内容
                                        <div style={{  display: 'flex', alignItems: 'center' }}>
                                            {products.length !== 0 ? (
                                                <Space size="small">
                                                    <Button onClick={openInventoryItemsManager}>項目管理</Button>
                                                    <Button className="header-button" onClick={() => setIsExportModalOpen(true)} type="default">匯出</Button>
                                                    <Button className="header-button" onClick={() => setShowGuide(true)} type="default">說明</Button>
                                                    <Button danger onClick={openArchiveModal} type="primary">盤點結束</Button>
                                                </Space>
                                            ) : (
                                                <Space size="small">
                                                    <Button onClick={startInventory} type="primary">盤點開始</Button>
                                                    <Button className="header-button" onClick={() => setShowGuide(true)} type="default">說明</Button>
                                                </Space>
                                            )}
                                        </div>
                                    )}
                                </Col>

                            </Row>
                        </Layout.Header>
 <div></div>
                        <Layout.Content style={{ padding: '20px', height: 'calc(100vh - 26vh)' }}>
                            {products.length !== 0 ?
                                <form id='main' autoComplete='no'>
                                    <div style={{ maxWidth: '720px', minWidth: '80%', margin: '0 auto', marginBottom: '10px' }} >
                                        <Row style={{ marginBottom: '10px' }} >
                                            <Col flex={3} style={{ textAlign: 'left' }}>
                                                <Space>
                                                    <Space.Compact>
                                                        <Button type={filterStatus === '全部品項' ? 'primary' : 'default'} onClick={() => toggleFilter('全部品項')} >{isMobile ? '全顯' : '全部品項'} </Button>
                                                        <Button type={filterStatus === '未盤點品項' ? 'primary' : 'default'} onClick={() => toggleFilter('未盤點品項')} >{isMobile ? '未盤' : '未盤點品項'} </Button>
                                                        <Button type={filterStatus === '已盤點品項' ? 'primary' : 'default'} onClick={() => toggleFilter('已盤點品項')} >{isMobile ? '已盤' : '已盤點品項'} </Button>
                                                    </Space.Compact>
                                                    <Button className="header-button" onClick={showDrawer} type="default" >篩選</Button>
                                                    <Button onClick={toggleMode} type={isCalculatorMode ? 'primary' : 'default'}> {isCalculatorMode ? '關閉計算機' : '開啟計算機'}  </Button>

                                                </Space>
                                            </Col>
                                            <Col flex={2} style={{ textAlign: 'right' }}>
                                                <Space>
                                                    {!isMobile ? <Input allowClear placeholder="搜尋產品名稱..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} style={{ width: '100%' }} /> : ''}
                                                </Space>
                                            </Col>
                                        </Row>
                                        <Table
                                            columns={columns}
                                            dataSource={filteredProducts.filter(product => product.停用 !== true)}
                                            rowKey="商品編號"
                                            pagination={false}
                                            size="small"
                                            scroll={{ x: 400, y: 'calc(100vh - 30vh)' }}
                                        />
                                    </div>


                                </form>
                                : !loading && (
                                    <Result
                                        status="404"
                                        title="當前門市本月尚無數據"
                                        subTitle={<><p>或許是尚未開始盤點，請點選下方按鈕開始作業。</p><p>當前門市：{storeName || '未選'}</p></>}
                                        extra={<Button type="primary" onClick={startInventory}> 開始盤點 </Button>}
                                    />)}

                        </Layout.Content>

                        <Layout.Footer style={{ zIndex: '9999', position: 'fixed', bottom: '0', left: '0', right: '0', textAlign: 'center', padding: '3px', backgroundColor: '#f5f5f5', borderTop: '1px solid #ccc' }}>
                            <p style={{ margin: '0px' }}>© 2024-{new Date().getFullYear()} edc-pws.com. All rights reserved.</p>
                        </Layout.Footer>
                    </Layout>
                
            )}
            <>

                <Analytics />

                <Drawer title="篩選" width='auto' placement="left" onClose={onClose} open={open}>
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', padding: '10px', margin: '5px', overflowX: 'auto' }} >
                        <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
                            <strong>廠商</strong>
                            <div className="list" style={{ display: 'flex', flexDirection: 'column', overflowX: 'auto', alignItems: 'flex-start', marginLeft: '10px' }}>

                                {allVendors.map(vendor => {
                                    const { totalValid, totalWithQuantities, percentage } = vendorStatistics[vendor] || { totalValid: 0, totalWithQuantities: 0, percentage: 0 }; // 確保取得正確的數據
                                    return (

                                        <label key={vendor} className="filter-item" style={{ display: 'flex', margin: '5px 0', alignItems: 'center' }} >
                                            <input
                                                style={{ alignItems: 'flex-end', marginRight: '8px' }}
                                                type="checkbox"
                                                checked={selectedVendors.includes(vendor)}
                                                onChange={() => handleVendorChange(vendor)}
                                            />
                                            {vendor}
                                            <Progress percent={percentage} style={{ marginLeft: '10px', width: '100px' }} /> {/* 顯示百分比 */}
                                        </label>
                                    );
                                })}
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap', marginTop: '10px' }}>
                            <strong>庫別</strong>
                            <div className="list" style={{ display: 'flex', flexDirection: 'column', overflowX: 'auto', alignItems: 'flex-start', marginLeft: '10px' }}>

                                {allLayers.map(layer => (
                                    <label key={layer} className="filter-item" style={{ display: 'flex', margin: '5px 0', alignItems: 'center' }} >
                                        <input
                                            style={{ alignItems: 'flex-end', marginRight: '8px' }}
                                            type="checkbox"
                                            checked={selectedLayers.includes(layer)}
                                            onChange={() => handleLayerChange(layer)}
                                        />
                                        {layer}
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div >

                </Drawer>
                {contextHolder}
                {messageHolder}

                {/* 其他 Modal 與提示框 */}
                {isStoreChooseModalOpen && (
                    <Modal
                        footer={null}
                        title={<div style={{ textAlign: 'center' }}>門市選擇</div>}
                        open={isStoreChooseModalOpen}
                        maskClosable={false}
                        keyboard={false}
                        mask={true}
                        centered={true}
                        closable={false}
                    >
                        <Space>
                            {Object.keys(storeMappings).map(store => (
                                <Button
                                    key={storeMappings[store]} // 確保每個按鈕有唯一的 key
                                    onClick={() => {
                                        setStoreName(storeMappings[store]); // 設定選擇的門市
                                        setIsStoreChooseModalOpen(false); // 關閉模態框
                                    }}
                                >
                                    {store} {/* 顯示門市名稱 */}
                                </Button>
                            ))}
                        </Space>
                    </Modal>
                )}
                <InventoryUploader
                    ref={inventoryUploaderRef}
                    fetchProducts={fetchProducts}
                    allLayers={allLayers}
                    allVendors={allVendors}
                    storeName={storeName} // 将选中的门市传递给 InventoryUploader
                    displayStoreName={getStoreNameByCode(storeName)} // 传递店名

                />

                <ArchiveModal
                    isOpen={isArchiveModalOpen}
                    onClose={closeArchiveModal}
                    products={products}
                    storeName={storeName}
                />
                <InventoryItemsManager
                    isOpen={isInventoryItemsManagerOpen}
                    onClose={() => setIsInventoryItemsManagerOpen(false)}
                    products={products}
                    fetchProducts={fetchProducts}
                    storeName={storeName}
                    allVendors={allVendors} // 傳遞供應商數組
                    allLayers={allLayers} // 傳遞庫別數組
                />

                <ExportModal
                    isOpen={isExportModalOpen}
                    onClose={() => setIsExportModalOpen(false)}
                    products={products}
                />
                <GuideModal isOpen={showGuide} onClose={() => setShowGuide(false)} />
                {/* 顯示工具提示 */}
                {hoveredProduct && (<div style={{ textAlign: 'left', fontSize: '12px', position: 'fixed', backgroundColor: 'white', border: '1px solid #ccc', padding: '5px', borderRadius: '5px', zIndex: 1000, top: tooltipPosition.top, left: tooltipPosition.left, }}>
                    期初存量：{initialStock} <br />
                    規格：{currentSpec}
                </div>
                )}
                {/* 顯示載入提示*/}

                <Modal
                    isOpen={isModalOpen}
                    title={modalContent.title}
                    message={modalContent.message}
                    type={modalContent.type}
                    onClose={() => setIsModalOpen(false)}
                />
                {/* 顯示錯誤訊息的 Modal */}
                {errorModal && (
                    <Modal
                        isOpen={!!errorModal}
                        title={errorModal.title}
                        message={errorModal.message}
                        onClose={() => setErrorModal(null)}
                        type="error"
                    />
                )}
                {/* 顯示離線提示 */}
                {isOffline && (
                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000, }}>
                        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', textAlign: 'center' }}>
                            <h2>您已離線</h2>
                            <p>請檢查網路連線是否正常。</p>
                            <button onClick={() => window.location.reload()}>重新整理</button>
                        </div>
                    </div>

                )
                }


                {/* 管理员密码验证模态框 */}
                {isPasswordModalVisible && (
                    <AdminPasswordModal
                        onSuccess={handlePasswordSuccess} // 密码验证成功后的处理
                    />
                )}

                {/* 通知发布组件 */}
                {isAnnouncementModalVisible && (
                    <Announcement
                        storeMappings={storeMappings} // 将 storeMappings 传递给 Announcement
                        onClose={() => setIsAnnouncementModalVisible(false)}
                    />
                )}

            </>
        </div>

    );
};

export default App;